@font-face {
    font-family: 'Work Sans';
    src: local('WorkSansWorkSans-VariableFont_wght'), url(./fonts/WorkSans/WorkSans-VariableFont_wght.ttf) format('truetype')
}

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: 'Work Sans', sans-serif !important;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .05em;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

main.main-content {
    flex-grow: 1;
    padding-top: 50px;
}

header, main, footer {
    flex-shrink: 0;
}

input:focus {
    border-bottom: 1px solid #d9000d !important;
    box-shadow: 0 1px 0 0 #d9000d !important;
}

input:focus + label {
    color: #d9000d !important;
}

input[type="checkbox"].filled-in:checked + span:not(.lever):after {
    border: 2px solid #d9000d;
    background-color: #d9000d;
}

input[type="checkbox"].filled-in.tabbed:focus {
    border: 2px solid #d9000d;
    background-color: #d9000d;
}

a {
    -webkit-transition: all .2s linear;
    color: #333;
    cursor: pointer;
    text-decoration: none !important;
    background-color: transparent;
}

ul.services-ul li:before {
    content: '✓';
    color: green;
    width: 20px;
    display: inline-block;
    transform: scale(1.5)
}

.row {
    margin-bottom: 0px !important;
}

.row.navbar {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0;
}

.input-field input[type="text"].error {
    border-bottom: 1px solid red;
}

.MuiInput-underline:after {
    border-bottom: 1px solid #d9000d !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #333 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #d9000d !important;
    font-size: 1rem !important;
}

.MuiInputBase-input, .MuiInputBase-root, .MuiInputLabel-root, .MuiAutocomplete-popper, input, span {
    font-family: 'Work Sans', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    letter-spacing: .05em !important;
    line-height: 20px !important;
    margin-bottom: 5px !important;
}

.MuiFormHelperText-root, div.error {
    color: red !important;
    margin-top: 3px;
    font-size: 0.75rem !important;
    font-family: inherit !important;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.input-field label.error {
    color: red;
}

/* tabbing on checkbox */
input[type="checkbox"].filled-in.tabbed:checked:focus+span:not(.lever):after {
    background-color: #d9000d;
    border-color: #d9000d;
}

header.header {
    width: 100%;
    background: #fff;
    z-index: 500;
    display: block;
}

.navbar-contact {
    padding: 15px;
    -webkit-transition: all 0.2s linear;
    display: flex;
    align-items: center;
}

.navbar-contact-left {
    padding-right: 30px;
}

.navbar-contact-a {
    color: #333;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    height: 100%;
    font-weight: 500;
    align-items: center;
}

.navbar-contact-a:hover {
    color: #d9000d;
}

.navbar-contact-a svg {
    width: 15px;
    margin-right: 10px;
    height: 15px;
}

.navbar-logo {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
}

.navbar-logo a svg {
    height: 70px;
    width: 200px;
    margin: 5px 15px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.check-range-header {
    margin-bottom: 25px;
    text-align: right;
    font-weight: 600;
    font-size: 36px;
    line-height: initial;
}

@media (min-width: 1400px) {
    .check-range-content {
        padding: 50px 200px;
    }
}

@media (min-width: 1051px) and (max-width: 1399px) {
    .check-range-content {
        padding: 50px 150px;
    }
}

@media (min-width: 1050px) {
    .check-range-content {
        padding: 50px 100px;
    }
}

.btn-light.rfc-button-back {
    width: 100%;
    border-radius: 35px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    letter-spacing: .05rem;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #000;
}

.btn-light.rfc-button-back:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-primary.rfc-button {
    width: 100%;
    color: #fff;
    background-color: #d9000d;
    border-color: #d9000d;
    border-radius: 35px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    letter-spacing: .05rem;
    margin-top: 15px;
}

.btn-primary.rfc-button:not(:disabled):not(.disabled):active, .btn-primary.rfc-button:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #d9000d;
    border-color: #d9000d;
}

.btn-primary.rfc-button:not(:disabled):not(.disabled):active:focus, .btn-primary.rfc-button:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}

.btn-primary.rfc-button.selected {
    background-color: #333;
    border-color: #333;
    box-shadow: none;
}

@media (max-width: 768px) {
    .rfc-button {
        margin-bottom: 15px;
    }
}

.btn.btn-primary:focus {
    background-color: #333;
    border-color: #333;
    box-shadow: none;
}

.btn-primary.rfc-button:hover {
    background-color: #333;
}

.button-row {
    padding-top: 25px;
}

.centered {
    text-align: center;
}

.provided-address {
    display: flex;
    justify-content: space-between;
}

.footer-copyright {
    margin-top: 50px;
    font-size: 9px;
    font-weight: 300;
    padding-bottom: 90px;
    background: #efefef;
    width: 100%;
}

.footer-copyright .row-border {
    padding-top: 15px;
    margin: 0;
    border-top: 1px solid #aaa;
}

.footer-copyright .order-2 {
    text-align: right;
}

.footer-a {
    color: #333;
}

.footer-a:hover {
    color: #d9000d;
}

.p-0 {
    padding: 0;
}

input.MuiInputBase-input {
    height: 1rem !important;
    border-bottom: none !important;
    box-shadow: none !important;
}

.f-modal-alert {
    margin: 20px;
    padding: 25px 0;
}

.f-modal-alert .f-modal-icon {
    border-radius: 50%;
    border: 4px solid gray;
    box-sizing: content-box;
    height: 80px;
    margin: 20px auto;
    padding: 0;
    position: relative;
    width: 80px;

    // Success icon
    &.f-modal-success,
    &.f-modal-error {
        border-color: #A5DC86;

        &:after,
        &:before {
            background: #fff;
            content: '';
            height: 120px;
            position: absolute;
            transform: rotate(45deg);
            width: 60px;
        }

        &:before {
            border-radius: 120px 0 0 120px;
            left: -33px;
            top: -7px;
            transform-origin: 60px 60px;
            transform: rotate(-45deg);
        }

        &:after {
            border-radius: 0 120px 120px 0;
            left: 30px;
            top: -11px;
            transform-origin: 0 60px;
            transform: rotate(-45deg);
        }

        .f-modal-placeholder {
            border-radius: 50%;
            border: 4px solid rgba(165, 220, 134, .2);
            box-sizing: content-box;
            height: 80px;
            left: -4px;
            position: absolute;
            top: -4px;
            width: 80px;
            z-index: 2;
        }

        .f-modal-fix {
            background-color: #fff;
            height: 90px;
            left: 28px;
            position: absolute;
            top: 8px;
            transform: rotate(-45deg);
            width: 5px;
            z-index: 1;
        }

        .f-modal-line {
            background-color: #A5DC86;
            border-radius: 2px;
            display: block;
            height: 5px;
            position: absolute;
            z-index: 2;

            &.f-modal-tip {
                left: 14px;
                top: 46px;
                transform: rotate(45deg);
                width: 25px;
            }

            &.f-modal-long {
                right: 8px;
                top: 38px;
                transform: rotate(-45deg);
                width: 47px;
            }
        }
    }
}

.check-other-address {
    margin-top: 60px;
}

.check-other-address:hover {
    color: #d9000d;
    cursor: pointer;
}

.contact {
    margin-top: 60px;
    font-weight: 600;
}

.contact-phone {
    text-decoration: none;
    color: inherit;
}

.contact-phone:hover {
    color: #d9000d;
}


// ANIMATIONS BELOW

animateSuccessTip {
    animation: animateSuccessTip .75s;
}

.animateSuccessLong {
    animation: animateSuccessLong .75s;
}

.f-modal-icon.f-modal-success.animate:after {
    animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
    animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
    animation: animateErrorIcon .5s;
}

.animateXLeft {
    animation: animateXLeft .75s;
}

.animateXRight {
    animation: animateXRight .75s;
}

.scaleWarning {
    animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
    animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes animateSuccessTip {
    0%,54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessLong {
    0%,65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@keyframes rotatePlaceholder {
    0%,5% {
        transform: rotate(-45deg);
    }

    100%,12% {
        transform: rotate(-405deg);
    }
}

@keyframes animateErrorIcon {
    0% {
        transform: rotateX(100deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0deg);
        opacity: 1;
    }
}

@keyframes animateXLeft {
    0%,
    65% {
        left: 82px;
        top: 95px;
        width: 0;
    }

    84% {
        left: 14px;
        top: 33px;
        width: 47px;
    }

    100% {
        left: 17px;
        top: 37px;
        width: 47px;
    }
}

@keyframes animateXRight {
    0%,
    65% {
        right: 82px;
        top: 95px;
        width: 0;
    }

    84% {
        right: 14px;
        top: 33px;
        width: 47px;
    }

    100% {
        right: 16px;
        top: 37px;
        width: 47px;
    }
}

@keyframes scaleWarning {
    0% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulseWarning {
    0% {
        background-color: #fff;
        transform: scale(1);
        opacity: 0.5;
    }

    30% {
        background-color: #fff;
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        background-color: #F8BB86;
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulseWarningIns {
    0% {
        background-color: #F8D486;
    }

    100% {
        background-color: #F8BB86;
    }
}

.lds-spinner {
    color: black;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}
.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: black;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

